@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Jost&family=Lato&family=Montserrat:wght@400;700&family=Oleo+Script&family=Open+Sans:wght@600&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,400&display=swap');

.drop-down {
  position: absolute;
  margin-left: 5%;

}

.drop-down-item {
  position: relative;
  right: 10rem;
  top: 8rem;
}

.Main-contents {
  padding: 2rem;
}

.Cards {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: auto;
  padding: 10px;
  border: 1px solid silver;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  width: 35rem;
  height: 8rem;
  cursor: pointer;
}

#total {
  letter-spacing: 5px;
}



.card-details>p:nth-child(1) {
  font-size: 25px;
}

.card-details>p:nth-child(2) {
  font-size: 20px;
}

.Cart-page {
  margin: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  overflow-y: scroll;
  height: 80vh;
}

.Icons {
  width: 4rem;
  height: 4rem;
  /* margin:10px; */
  border-radius: 50%;
  background-color: lightcyan;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details {
  text-align: center;
  font-family: 'Poppins', sans-serif;

}

.card-details>p {
  margin: 5px;
  line-height: 25px;
}

.revenue-filter>button {
  padding: 10px;
  background-color: blue;
  color: white;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  margin: 10px
}

.revenue-filter {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  align-items: flex-end;
}

select {
  border-radius: 8px;
}

.date-picker {
  border: 1px solid silver;

  position: absolute;
  top: 30%;
  /* left: 0;
    right: 0; */
  bottom: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.search-box {
  display: flex;
  align-items: center;
  border: 1px silver solid;
  background-color: white;
  width: fit-content;
  height: 45px;
  border-radius: 20px;
}

.search-box>.MuiSvgIcon-root {
  color: gray;
  padding: 10px;
  font-size: 45px;
}

.search-box>input {
  border: none;
  outline-width: 0;
  padding: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

.search-box>input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.Search-bar {
  display: flex;
  margin-top: 50px;
  margin: 1rem;
  flex-direction: column;
  align-items: flex-end;
}

.Table {
  border-radius: 15px;
  margin-top: 30px;
  border: 1px silver solid;
  margin: '10px 10px';
  max-width: 950
}

.Table-header {
  color: white !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  background-color: #303f9f !important;
}
.header> h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size:30px;
  margin:1rem;
  text-align: center;
}
.Order_title {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600 !important;
  margin: 1rem;
}

.animation {
  width: 30rem;
}

.logo-img {
  width: 20rem;
}

.page-left-side {
  width: 50%;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Login-page {
  display: flex;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  height: 100vh;
}

.page-right-side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Signin {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 1rem;
  align-items: center;
  border: 1px solid silver !important;
  border-radius: 20px;
  padding: 1rem;
}

.Login-btn {
  margin: 20rem;
}

#Signin>p {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600 !important;
  letter-spacing: 3px;
  /* margin: 1rem; */
}
.input-container>textarea{
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
#outlined-basic:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#outlined-adornment-password:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.Customer-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: auto;
  padding: 2rem;
}

.Customer-details>p {
  margin: 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
}
.Chat{
 position:sticky;
 bottom:0;
 margin-left:70%;
 width:22rem;
 height:30rem;
 display: flex;
 border-radius:8px 8px 0 0;
 flex-direction: column;
 /* background: #0072ff; */
 border:1px solid silver;
 background-color: white;
}
.Chat-section{
  padding:1rem;
  border-radius:8px 8px 0 0;
  background-color: #cfd9df;
  display: flex;
  align-items: center;
  
  justify-content:space-between;
  border:1px solid silver;
}
.Customer-details>p>span {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400 !important;
}

.Chat-container{
  background-color:white;
  flex:1;
  flex-direction: column;
  gap: 0.5rem;
  padding:30px;
  display: flex;
  flex-direction: column;
  overflow:auto;
}
.chat-footer{
  display: flex;
  align-items: center;
  border: 1px silver solid;
  background-color: #cfd9df;
  width: fit-content;
  height: 45px;
  border-radius: 20px;
}
.chat-footer>textarea{
  flex:1;
  /* border-radius:30px; */
  color: #cfd9df;
  border: none;
  outline-width: 0;
  padding: 3px;
  margin-right: 10px;
  margin-left: 10px;
  padding:10px;
  max-height:100px;
  border:none;
  overflow-y:auto;
  resize: none;
}
.input-container {
  display: flex;
  align-items: center;
  background-color: #cfd9df;
}

.input-container>textarea {
  background-color: #cfd9df;
  flex: 1;
  padding: 10px;
  border: none;
  resize: none;
}
.input-container>textarea::-webkit-scrollbar{
display: none;
}

.send-button-container {
  display: flex;
  align-items: center;
}

button {
  padding: 6px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}
.AudioRecorder{
  display:none;
}
.message{
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  color: black;
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width:18rem;
  width: fit-content;
  display: flex;
  word-wrap: break-word;
  flex-direction: column;
  gap: 0.5rem;
}
.message>span:nth-child(2){
  align-self: end
}

.input-container>textarea:focus{
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.Chat-container::-webkit-scrollbar{
  display:none;
  }
.chat-footer>textArea::-webkit-scrollbar{
display:none;
}
.chat-personal-details{
  display: flex;
  align-items: center;
  gap:1rem;
}
.Cart-items {
  margin: 1rem;
}

.Cart-items>p {
  font-family: 'Poppins', sans-serif;
  margin: 1rem;
  font-size: 20px;
  font-weight: 600 !important;
}

.Product-details {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.Product-details>img {
  width: 5rem;
  border-radius: 8px;
}

.Product-details>div>p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
}

.Action_buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
}

.btn {
  padding: 1rem;
}

.Order_Status {
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.Order-status-element {
  display: flex;
  gap: 20px;
  padding: 10px;
  font-size: 18px;
}

.Order-status-element>p {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
}

.Order-status-element>p>span {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

.Sales_report {
  margin: 1rem;
  padding: 1rem;
}

.Sales_report>p {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

.charts {
  width: auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 2rem;
  align-items: center;
}

.chart:nth-child(1) {
  width: 80rem;
  padding: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
}

.chart:nth-child(2) {
  width: 40rem;
  padding: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
}
.chart{
  background-color:white;
}
.chart>p{
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  font-size:20px;
}
#file-upload {
  /* display: none; */
}

.upload_report {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem;
  align-items: center;
}

#DialogTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

.dialog {
  border: 1px solid silver !important;
  border-radius: 8px !important;
  padding: 1rem !important;
}

.doctor-details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.doctor-details>p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

.doctor-time-details {
  text-align: center;
  margin: 1rem;
  padding: 1rem;
}

#schedule-time {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

#schedule {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

#time {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

#customized-dialog-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

.appointment-details>p {
  margin: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 16px;
}

.appointment-details>p>span {
  margin: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
}

.Patient-section {
  margin: 1rem;
  display: flex;

  gap: 1rem;
}

.patient-details {
  width: 20rem;
  height: 25rem;
  gap: 5px;
  border: 1px solid silver;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fefcfd;
  border-radius: 8px;
}
.paitent-results{
  margin: 1rem;
  margin-top:2rem;
}
.paitent-results>h1{
  font-family: 'Poppins', sans-serif;
  font-weight:600 !important;
  font-size:20px;
}
.patient-img {
  width: 10rem;
  border-radius: 50%;
  background-image: url('../public/blue-smooth-wall-textured-background.jpg');
}

#patient-name {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: 600 !important;
}

.patient-fitness {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;

}

.fitness {
  display: flex;
  gap: 2rem;
  margin: 1rem;
}

.patient-fitness-details {
  text-align: center;
  padding: 5px;
  /* margin:1rem; */
}

#age {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  letter-spacing: 4px;
}

#years {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}

.Start-Call {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-weight: 600 !important;
  background-image: linear-gradient(to right, #00c6ff 0%, #0072ff 51%, #00c6ff 100%);
  width: 8rem;
  padding: 8px;
  margin: 1rem;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;





}

.Start-Call:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.patient-more-details {
  width: 60rem;
  height: 25rem;
  border-radius: 8px;
  border: 1px solid silver;
  background-color: #fefcfd;
  padding: 2rem;
}

.paitent-contact-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem 1rem;
  height: 10rem;

}

.consultation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  border: 1px solid silver;
  border-radius: 8px;
  margin-top: 1rem;
}

.consultation-data {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

#datetype {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}

#time {
  color: #96a3aa;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
}

#consultation-mode-type {
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}

#consultation-mode {
  color: #96a3aa;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;

}

body {
  background-color: #f2f4f8;
}

#patient-contact-title {
  color: #96a3aa;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
}

#patient-contact-content {
  margin-top: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

.Upload-reports {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  color: #1157fd;
  cursor: pointer;
  /* justify-content: base; */
}

.Forms {
  width:100vw;
  /* padding: 5rem; */
  border-radius: 8px;
  margin-top:1rem;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}
#diet,#Practise{
  align-self: flex-start;
  margin-top:1rem;
  font-size:20px;
  font-family: 'Poppins', sans-serif;
  font-weight:500 !important;
}

.Practises{
  display: flex;
  gap:1rem
}
#outlined-multiline-static{
  width:25rem;
}
.Product-list{
 display: grid;
 grid-template-columns: repeat(2,1fr);
 place-items:center;
 grid-gap:1rem;
 grid-template-rows: auto;
}
.Product-card{
  width:15rem;
  padding:1rem;
  display:flex;
  align-items: center;
  border-radius:8px;
  gap:5px;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
}
.Practise{
height:4rem;
}
.formbtn{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.checkbox{
  display: flex;
  align-items: center;
  padding: 1rem;
  gap:1rem;
  font-family: 'Poppins', sans-serif;
  font-weight:400 !important;
}
.checkbox>input:focus{
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.submitformbtn{
  background-color: #0072ff;
  color:white;
  font-size:16px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-weight:400 !important;
  margin:2rem;
  
  /* margin-top: 5rem; */
  text-align: center;
  width:15rem;
  border-radius:8px;
  padding:1rem;
}
.supplements{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  font-family: 'Poppins', sans-serif;
  font-weight:500 !important;
  place-items:center;
  grid-gap:1rem;
  grid-template-rows: auto;
}
.Yogalist{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.Product-card>img{
  width:6rem;
  aspect-ratio:1/2;
  object-fit: cover;
}
.Product-card>p{
  /* font-size:20px; */
  font-family: 'Poppins', sans-serif;
  font-weight:400 !important;
}

.dialog-content{
padding: 2rem;
/* width:30rem; */
}
#outlined-multiline-static:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.diet {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-evenly;
}

.input {
  padding: 1rem;
  margin: 1rem;
  /* width:10rem; */
}

.label,
input {
  display: block;
}

.medicine {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
}

#outlined-controlled {
  width: 40rem;
}

#outlined-controlled:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#standard-size-small:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.add-more {
  align-self: flex-start;
  background-color: #0072ff;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size:14px;
  border-radius: 8px;
  padding: 8px;
  color: white;
  cursor: pointer;
}

.Form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem;
  margin-bottom:0px;
  padding: 2rem;
}

.Forms>h1 {
  font-size: 30px;
  color: black;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: 700 !important;
}

.prescription_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container{
  display: flex;
  align-items: center;
  justify-content: center;
  height:auto;
}
.content-box{
  padding:1rem;
}
.client-basic-information{
  width:71vw;
  height:70vh;
  margin-top:1rem;
  margin-bottom:0px;
  padding:2rem;
  border: 1px solid silver;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  overflow-y: auto;
  border-radius:10px;
}
.next-btn{
  text-transform: none;
  background-color: blue;
}
.diagnois>p,.Current-medication>p{
  font-family: 'Poppins', sans-serif;
  font-size:20px;
  font-weight:600;
}
.diagnois-list,.diet-question-list{
  display: grid;
  grid-template-columns:repeat(5,1fr);
  grid-template-rows: auto;
  grid-gap:1rem;
}
#remove-btn{
  display: flex;
  align-items: center;
  margin-top:3rem;
  justify-content: center;
  cursor: pointer;
}
.current-medication-list{
  display: grid;
  grid-template-columns:repeat(3,1fr);
  grid-template-rows: auto;
  grid-gap:1rem;
}
textarea{
  resize: none;
  padding:1rem;
  height:8rem;
  width:60rem;
  border:1px solid silver;
  border-radius:8px;
}
textarea:focus{
  outline: none;
}
.diet-question-list{
  font-family: 'Poppins', sans-serif;

  font-weight:500;
}
.diagnois-list>div>p,.current-medication-list>div>p{
  font-family: 'Poppins', sans-serif;
 font-size:14px;
  font-weight:500;
}
.add-more {
  background-color: #0072ff;
  font-family: 'Poppins', sans-serif;
  margin-top:1rem;
  width:8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  font-weight: 400 !important;
  border-radius: 8px;
  padding: 8px;
  color: white;
  cursor: pointer;
}
.action-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-buttons-box{
  display: flex;
  margin:1rem;
  align-items: center;
  gap:10rem;
  justify-content: space-between;
}
#name{
  border:1px solid silver;
  padding:14px;
  width:14rem;
  border-radius:8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  
}
#step{
  font-family: 'Poppins', sans-serif;
  font-size:18px;
  font-weight:700;
}
.client-basic-information::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.client-basic-information::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.client-basic-information::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}

.client-inputs{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows:auto;
  grid-gap:3rem;
}
.client-inputs>div>p{
  font-family: 'Poppins', sans-serif;
  font-size:14px;
  font-weight:500;
}
#name:focus{
  outline:none;
}
.last-section{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:2rem;
  flex-direction: column;
}
#last-section-p{
  font-family: 'Poppins', sans-serif;
  font-size:30px;
  font-weight:600;
}
.last-section-img{
  width:20rem;
}
.Login-page {
  display: flex;
  
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  height: 100vh;
}

.page-right-side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Signin {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 1rem;
  align-items: center;
  border: 1px solid silver !important;
  border-radius: 20px;
  padding: 1rem;
}

.Login-btn {
  margin: 20rem;
}

#Signin>p {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600 !important;
  letter-spacing: 3px;
  /* margin: 1rem; */
}

#outlined-basic:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#outlined-adornment-password:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.page-right-side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Signin {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 1rem;
  align-items: center;
  border: 1px solid silver !important;
  border-radius: 20px;
  padding: 1rem;
}

.logo-img {
  width: 20rem;
}

.page-left-side {
  width: 50%;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.page-right-side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disorders-card{
  padding:1rem;
}
.disorders-card>div{
  display: flex;
  align-items: center;
  width:20rem;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
}
.disorders-card>div>div{
  display: flex;
  align-items: center;
 gap:1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400 !important;
}
.disorders-card>div>input{
  font-weight:400;
  font-size: 14px;
}
.patient-information {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  border: 1px solid silver
}

#Patient-Information {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #1d86c6;

  font-weight: 700 !important;
}

.information {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 3rem;

  grid-gap: 1rem;
  grid-template-rows: auto;

}

#info-t {
  font-size: 16px;
  color: #1d86c6;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
}

.information>div>p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}
#table1{
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  max-width:30rem;
  margin: 20px auto;
  padding:1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#table1>thead {
  background-color:#007bff;
  color: white;
  font-size: 16px;
}

#table1>th,
#table1>td {
    padding: 12px 16px;
    text-align: left;
}


#table1>tbody tr {
    border-bottom: 1px solid #E3F1D5;
    background-color: #FFFFFF;
}
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; 
  animation: float 3s ease-in-out infinite;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.action-buttons{
  display: flex;
  align-items: center;
  gap:1rem;
  justify-content: center;
  margin:1rem;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}

.VoiceCall-page {
  display: flex;
  /* justify-content:center; */
  align-items: center;
  flex-direction: column;
  height: 100vh;

}

.VoiceCall-compontents {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.VoiceCall-compontents>img {
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.VoiceCall-compontents>p:nth-child(2) {
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  font-size: 20px;

}

.VoiceCall-compontents>p:nth-child(3) {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;

}

.VoiceCall-controlls {
  position: fixed;
  bottom: 1rem;
}

.VoiceCall-controlls-call-settings {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}

.hangoutcall {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  background-color: red;
}
.chat-head {
  padding: 20px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  color: white;
  /* border-radius:0px 13px 0px 0px; */
}

.chat-head-info {
  flex: 1;
  padding-left: 20px;
}

.chat-head-info>h3 {
  margin-bottom: 3px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: medium;
}

.Chat-head-right>.MuiSvgIcon-root {
  /* color: silver !important; */
}

.Chat-head-right {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 5rem;
}

.main {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #dadbd3
}

.containes {
  display: flex;
  width: 100vw;
  /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  height: 95vh;
  width: 95vw;
  border-radius: 13px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */

}

.chat-box {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  transition: 2s ease-out;
}

.chat-body {
  background-color: #CDDCDC;
  background-image: radial-gradient(at 50% 100%, rgba(255, 255, 255, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
  background-blend-mode: screen, overlay;
  /* height:80vh; */
  /* flex-grow:1; */
  flex-direction: column;
  gap: 0.5rem;
  padding: 30px;
  display: flex;
  /* height:80vh; */
  height: 36rem;
  /* height:auto; */
  flex-direction: column;
  overflow: auto;
}

.chat-body>audio {
  width: 14rem;
}

.chat-body::-webkit-scrollbar {
  display: none;
}

.message {
  /* background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%); */
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  color: black;
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: fit-content;
  display: flex;
  word-wrap: break-word;
  flex-direction: column;
  gap: 0.5rem;
}

.message>span:nth-child(2) {
  align-self: end
}

.own {
  align-self: flex-end;
  color: white;
  border-radius: 1rem 1rem 0 1rem;
  background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
}

.chat-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 65px;
  cursor: pointer;
  background-color: #E3F1D5;
  width: 100%;
  /* position: fixed;
  
  bottom: 0; */
  /* flex:1; */
  border-top: 1px solid lightgray;
}

.chat-footer>textarea {
  /* flex:1; */
  width: 100%;
  border-radius: 30px;
  padding: 10px;
  max-height: 100px;
  border: none;
  outline: 1px solid silver;
  overflow-y: auto;
  resize: none;
}

.sendbtn {
  padding: 10px;
  background-color: #0d8aed;
  color: #ededed;
  cursor: pointer;
  border-radius: 50%;
}
.bookappointmentbtn {
  display: flex;
  justify-content: center;
  /* margin-top:5rem; */
  margin: 3rem;
  align-items: center;
}

.BookAppointment-page {
  padding: 1rem;
  margin-bottom: 5rem;

}

.Calendar {
  box-shadow: none !important;
  /* width:30rem !important;
  min-height:auto!important; */
}

.Calendar__day.-selected {
  background-color: #0076BE !important;
}

.Datepick {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
}

.Calendar__monthSelector,
.Calendar__yearSelector {
  /* background-color: #0d8aed!important; */
}

.BookAppointment-page>h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.slotsunavailable {
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: lightcyan;
  border-radius: 10px;
  height: 10rem;
}

.animecontainer {
  display: flex;
  flex-direction: column;
}

.animecontainer>img {
  width: 2rem;
  align-self: flex-end;
}

.slotsunavailable>div>p {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.Orders-card {
  width: auto;
  border: 1px solid silver;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
}

.timings {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row: auto;
  place-items: center;
  grid-gap: 3rem;
}

.pickdate {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.slots {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  border-radius: 8px;
  width: 5rem;
  height: 4rem;
  padding: 2px;
  cursor: pointer;
  align-items: center;
}

.slots>p:nth-child(1) {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
}

.slots>p:nth-child(2) {
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}

#timings {
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  font-size: 25px;
}
.agreeandcontinue {
  padding: 1rem;
}

.appointment-booked {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}
.conset-form {
  width: auto;
  height: 22rem;
}
.current_Schedule{
  padding:1rem;
  margin:1rem;

}
.current_Schedule h1{
  padding:5px;
  width:10rem;
  text-align:center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  border-radius:8px;
  font-size: 16px;
  background-color: lightsalmon;
}
.current_Schedule>div{
  padding:1rem;
  padding-left:0;
  /* margin:1rem; */
}
.current_Schedule>div>p{
  margin-top:10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}
.current_Schedule>div>p>span{
  font-weight: 400 !important;
}
.disclaimer{
  margin: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}
.ctTitle{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 500 !important;
  background-color:#0076BE;
  text-align: center;
  color: white;
}
@media only screen and (max-width:1024px) {
  .Cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }

  .card {
    width: 20rem;
  }

  .charts {
    flex-direction: column-reverse;
  }

  .Product-details {
    flex-direction: column;
  }
  
  .Customer-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row: auto;
    grid-gap: 2rem, 1rem;
    padding: 0rem;
  }

  .Action_buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row: auto;
    gap: 1rem;
    padding: 2rem;
    grid-gap: 2rem, 1rem;
  }

  .Login-page {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .upload_report {
    flex-direction: row-reverse;
  }

  .chart:nth-child(1) {
    width: 30rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }

  .chart:nth-child(2) {
    width: 25rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }
  .patient-more-details {
    width:auto;
    height:auto;
    border-radius: 8px;
    border: 1px solid silver;
    background-color: #fefcfd;
    padding: 2rem;
  }
  .paitent-contact-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:1rem;
    height:auto;
  
  }
  .consultation {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    grid-gap:0px;
    height:auto;
    padding:0;
    border: 1px solid silver;
    border-radius: 8px;
    margin-top:6rem;
  }
  .Patient-section {
    /* margin: 1rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .patient-details {
    width: auto;
  }
}

@media only screen and (max-width:640px) {
  .revenue-filter {
    margin: 0px;
  }

  .Cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0rem;
  }

  .card {
    width: 20rem;
  }

  .chart:nth-child(1) {
    width: 25rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }

  .chart:nth-child(2) {
    width: 20rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }

  .Customer-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row: auto;
    padding: 0rem;
  }

  .Product-details {
    flex-direction: column;
  }
  .Patient-section {
    /* margin: 1rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .Cart-page {
    width: 20rem;
    padding: 0rem;
    margin: 10px;
  }

  .upload_report {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .doctor-details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .patient-more-details {
    width:20rem;
    height:auto;
    border-radius: 8px;
    border: 1px solid silver;
    background-color: #fefcfd;
    padding: 2rem;
  }
  .paitent-contact-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap:10px;
    height:auto;
  
  }
  .consultation {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    place-items: center;
    grid-gap:0px;
    height:auto;
    padding:0;
    border: 1px solid silver;
    border-radius: 8px;
    margin-top:6rem;
  }
  .Forms {
    width:22rem;
    padding: 3rem;
    border-radius: 8px;
    background: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  #outlined-controlled{
    width:15rem;
  }
  .medicine,.diet{
   flex-direction: column;
  }
  #standard-size-small{
    width:15rem;
  }
 
  #outlined-multiline-static{
    width:15rem;
  }
}